<!--
  Tag页面的大多数代码都是直接从index页面拷贝的
  不同的是 Tag展示的数据是根据url中的Tagname作为查询依据获取的数据
-->
<template>
  <div>
    <Header1></Header1>
    <Header></Header>
    <!-- banner图 -->
    <div class="banner" :style="{backgroundImage: 'url(' + coverImgUrl() +')', height: getHeight()}">
      <div class="wrap" :style="{height: getHeight()}">
        <div class="word-wrapper">
          <span class="title">{{label_name}}</span> <br>
          <span class="dec"></span>
        </div>
      </div>
    </div>
    <!-- content -->
    <BlogItem :blogs="blogs"></BlogItem>
    <el-backtop class="up"> <i class="el-icon-top"></i></el-backtop>
    <Version></Version>
  </div>
</template>


<script>
import Header1 from "../components/Header1.vue"  ;
import Banner from "../components/Banner.vue"  ;
import Header from "../components/Header.vue"  ;
import BlogItem from "../components/BlogItem.vue"  ;
import Version from "../components/Version.vue"  ;
export default {
  name : 'Tag' , 
  components:{Header1,Banner,Header,BlogItem,Version} , 
  data(){
    return{
      blogs:{} , 
      label_name:'' ,   // 后台是label_name   前台是tag_name
      // currentPage:1 , 
      // total:0 , 
      // pageSize:5 , 
      collorArr:["pink" , "#AFEEEE" , "#abf" , "#9370DB" , "#F4A460" , "#90EE90" , "#6495ED" , "	#A9A9A9" , "#DDA0DD" , "	#F0E68C"] ,
      bgCol:"#abf",
      imgUrls:[
        "https://linwu.oss-cn-shanghai.aliyuncs.com/imgs/bg3/tag1.jfif",
        "https://linwu.oss-cn-shanghai.aliyuncs.com/imgs/bg3/tag2.jfif",
        "https://linwu.oss-cn-shanghai.aliyuncs.com/imgs/bg3/tag3.jfif",
        "https://linwu.oss-cn-shanghai.aliyuncs.com/imgs/bg3/tag4.jfif",
        "https://linwu.oss-cn-shanghai.aliyuncs.com/imgs/bg3/tag5.jfif",
        "https://linwu.oss-cn-shanghai.aliyuncs.com/imgs/bg3/tag6.jfif",
        "https://linwu.oss-cn-shanghai.aliyuncs.com/imgs/bg3/tag7.jfif" , 
        "https://linwu.oss-cn-shanghai.aliyuncs.com/imgs/bg3/tag8.jfif" , 
        "https://linwu.oss-cn-shanghai.aliyuncs.com/imgs/bg3/tag9jfif.jfif" , 
        "https://linwu.oss-cn-shanghai.aliyuncs.com/imgs/bg3/tag10.jfif" , 
      ]
    }
  } ,
  methods:{
    getHeight(){
      return window.innerHeight + 'px' ; 
    } , 
    getBlogsByTagName(){
      const _this = this ; 
      _this.$axios.get("/getBlogsByLabelName?label_name="+ this.label_name).then(res => {
        this.blogs = res.data.data ; 
      }) ; 
    } ,
    coverImgUrl(){
      let num = Math.floor(Math.random()*10);  
      return this.imgUrls[num] ; 
    }
  } , 
  created(){
    this.label_name = this.$route.params.tagName ; 
    this.getBlogsByTagName() ; 
    this.coverImgUrl() ;
    // this.page(this.currentPage) ; 
    // 页面加载完毕，展示为顶部
    window.scroll(0,0) ; 

  } ,
  // watch:{
  //   // 监听url变化，获取要查询的tagName
  //   $route(to,from){
  //     let url = to.path ; 
  //     let type_id = url.charAt(url.length-1) ; 
  //     // 然后重新查询，并重新渲染页面
  //     const _this = this ; 
  //     _this.$axios.get("/getBlogsByTypeId?type_id="+ type_id).then(res => {
  //       this.blogs = res.data.data ; 
  //     }) ; 
  //   }
  // }
}
</script>


<style scoped>
.banner{
  width: 100vw;
  height: 100vh;
  /* background-image: url("../img/k-on1.jpeg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* position: absolute;
  left: 0;
  top: 0;
  overflow: hidden; */
  opacity: 1;
  margin-bottom: 5rem;
}
.banner .wrap{
  width: 100vw;
  height: 100vh;
  background-color: rgba( 0, 0, 0, 0.2);
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
}
.banner .wrap .word-wrapper{
  position: absolute;
  top: 35%;
}
.banner .wrap .word-wrapper .title{
  color: white;
  font-size: 40px;
}
.banner .wrap .word-wrapper .dec{
  color: white;
  font-size: 15px;
}
.up{
  background-color: rgba(255, 255, 255, 0.5);
  
}
</style>