 <!-- 展示博客条目的组件 -->
<template>
  <div >
    <div class="content">
      <el-card class="box-card" v-for="(blog,index) in blogs" :key="index" 
        v-bind:style="{backgroundColor:getColor() , backgroundImage: 'url(' + coverImgUrl2() + ') ' }" >
        <div class="cover"> <!--遮罩-->
          <router-link :to="{name:'BlogDetail' , params:{blogId:blog.id}}"> 
            <div  class="text item" >
              <div class="wrapper">
                <div class="title">{{blog.title}}</div>    
                <div class="description">{{blog.description}}</div>
              </div>
            </div>
          </router-link>
        </div>
      </el-card>
      <!-- 分页 -->
      <!-- <el-pagination class="page"
        background
        layout="prev, pager, next"
        :current-page="currentPage"
        :page-size="pageSize"
        :total="total"
        @current-change=page>
      </el-pagination> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlogItem',
  props: ['blogs'],   // 这个blogs是调用当前组件时需要传过来的数据对象，没有加分页
  data(){
    return{
      collorArr:["pink" , "#AFEEEE" , "#abf" , "#9370DB" , "#F4A460" , "#90EE90" , "#6495ED" , "	#A9A9A9" , "#DDA0DD" , "	#F0E68C"] ,
      bgCol:"#abf" , 
      imgUrls2:[] ,
    }
  } , 
  methods:{
    // 获取我们设置的随机颜色作为展示博客的card的背景
    getColor(){
      let num = Math.floor(Math.random()*10);  
      return this.collorArr[num] ; 
    }  , 
    // 获取博客item背景图
    coverImgUrl2(){
      let num = Math.floor(Math.random()*this.imgUrls2.length);  
      // console.log(num);
      return this.imgUrls2[num] ; 
    } ,  
    // 获取blog的title和描述的背景图片
    coverImgUrlBlog(){
      const _this = this ; 
      _this.$axios.get("/getBgBlog" ).then(res => {
        // console.log(res) ; 
        this.imgUrls2 = res.data.data ; 
      }) ; 
    }
  } , 
  created(){
        this.coverImgUrlBlog() ; 
    // this.page(this.currentPage) ; 
  }
}
</script>


<style scoped>
@media all and (min-width:850px) {
  .content{
    width: 45vw;
    text-align: center;
    margin: 0 auto;
    margin-top: 5rem;
    margin-bottom: 100px;
    /* position: absolute;
    top: 140px;
    left: 0;
    bottom: 0;
    right: 0; */
  }
  .content .box-card{
    position: relative;
    width: 100%;
    height: 27vh;
    box-sizing: border-box;
    background-color: pink;
    border-radius: 10px;
    margin: 60px 0 40px 0;
    background-position: center;
    background-repeat:no-repeat;
    background-size: 100%;
    /* filter:Alpha(Opacity=100);opacity:0.9; */
  }
  .content .box-card .cover{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba( 0, 0, 0, 0.2);
  }
  .content .box-card:hover{
    cursor: pointer;
    /* width: 980px;
    height: 210px; */
    transform:  scale(1.03) ; 
    box-shadow: 1px 5px 20px rgba( 0, 0, 0, 0.3);
  }
  .content .box-card .wrapper{
    width: 35vw;
    margin:  0 auto;
    margin-top: 8vh;
    text-align: center;
    color: black;
  }
  .content .box-card .title{
    font-size: 24px;
    font-weight: 500;
    color: white;
  }
  .content .box-card .description{
    margin-top: 10px;
    font-size: 14px;
    color: white;
  }
  .bottom{
    width: 100%;
    height: 10rem;
    background-color: pink;
  }
  
}


@media all and (max-width:850px) {
  .content{
    width: 96vw;
    text-align: center;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 100px;
    /* position: absolute;
    top: 720px;
    left: 0;
    bottom: 0;
    right: 0; */
  }
  .content .box-card{
    position: relative;
    width: 100%;
    height: 19rem;
    box-sizing: border-box;
    background-color: pink;
    border-radius: 10px;
    margin: 40px 0 40px 0;
    background-position: center;
    background-repeat:no-repeat;
    background-size: 100%;
    /* filter:Alpha(Opacity=100);opacity:0.9; */
  }
  .content .box-card .cover{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba( 0, 0, 0, 0.2);
  }
  /* .content .box-card:hover{
    cursor: pointer;
    transform:  scale(1.03) ; 
    box-shadow: 1px 5px 20px rgba( 0, 0, 0, 0.3);
  } */
  .content .box-card .wrapper{
    width: 60vw;
    margin:  0 auto;
    margin-top: 65px;
    text-align: center;
    color: black;
  }
  .content .box-card .title{
    font-size: 1.8rem;
    font-weight: 500;
    color: white;
  }
  .content .box-card .description{
    margin-top: 1rem;
    font-size: 1.2rem;
    color: white;
  }
  .out{
    position: relative;
  }
  .bottom{
    width: 100%;
    height: 20rem;
    background-color: pink;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
</style>
