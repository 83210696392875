<!-- banner图组件 -->
<template>
<div>
  <!-- banner图 -->
  <div class="banner" :style="{backgroundImage: 'url(' + imgUrl.url + ')', height: getHeight()}">
    <div class="wrap" :style="{height: getHeight()}">
      <div class="word-wrapper">
        <span class="title">{{imgWord.title}}</span> <br>
        <span class="dec">{{imgWord.des}}</span>
      </div>
    </div>
  </div>
</div>
</template>


<script>
export default {
  name : 'Banner' , 
  data(){
    return {
      blogs: {} , 
      currentPage:1 , 
      total:0 , 
      pageSize:5 , 
      collorArr:["pink" , "#AFEEEE" , "#abf" , "#9370DB" , "#F4A460" , "#90EE90" , "#6495ED" , "	#A9A9A9" , "#DDA0DD" , "	#F0E68C"] ,
      bgCol:"#abf" , 
      imgUrl:{/*url:'https://linwu.oss-cn-shanghai.aliyuncs.com/imgs/bg1/zll.jpg'*/} ,
      imgWord:{/*title:"在我们不曾了解的地方，总有些未曾看到的心酸。" , des:"At the place we don't know, there is always some sadness we haven't seen."*/} ,
    } 
  } , 
  methods:{
    getHeight(){
      return window.innerHeight + 'px' ; 
    } , 
    // 获取我们设置的随机颜色作为展示博客的card的背景
    getColor(){
      let num = Math.floor(Math.random()*10);  
      return this.collorArr[num] ; 
    }  , 
    // 获取banner背景图片和文字
    coverImgUrl(){
      const _this = this ; 
      _this.$axios.get("/getBg").then(res => {
        this.imgUrl = res.data.data.url ; 
        this.imgWord = res.data.data.word ; 
        // console.log("-------------------------------------");
        // console.log(res);
        // console.log(this.imgUrl);
        // console.log(this.imgWord);
        // console.log("-------------------------------------");
      }) ; 

      let num = Math.floor(Math.random()*(29-1+1))+1 ;
      // return this.imgUrl.url ; 
    },
  } , 
  created(){
    this.coverImgUrl() ; 
  }
}
</script>


<style scoped>
.banner{
  width: 100vw;
  height: 100vh;
  background-image: url("../img/k-on1.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* position: absolute;
  left: 0;
  top: 0; */
  overflow: hidden;
  opacity: 1;
}
.banner .wrap{
  width: 100vw;
  height: 100vh;
  /* opacity: 0.5; */
  background-color: rgba( 0, 0, 0, 0.15);
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
}
.banner .wrap .word-wrapper{
  position: absolute;
  top: 35%;

  /* padding-top: 280px; */
}
.banner .wrap .word-wrapper .title{
  color: white;
  font-size: 40px;
}
.banner .wrap .word-wrapper .dec{
  color: white;
  font-size: 15px;
}
</style>